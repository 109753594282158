<template>
  <div
    class="navigation-section !bg-transparent w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative"
      :class="{ 'active': item.isActive }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        class="text-[#CBCBCB] icon"
        height="24"
        width="24"
      />
      <div v-if="navState === 'full'" class="label flex justify-between items-center w-full">
        <span class="block">{{ item.label }}</span>
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const items = computed(() => [
  {
    icon: 'medal',
    label: 'Holders Only',
    isActive: route.path.includes('meta-winners'),
    action: () => sportsBettingRedirect('/promotions/meta-winners/'),
  },
]);

</script>

<style lang="scss" scoped>
.navigation-section {
  .nav-button {
    background: #15171D;

    .label {
      color: white;
    }
  }
  .nav-button:hover {
    background:#1A1D26
  }
  .nav-button.active {
    background:#1A1D26
  }
}
</style>
