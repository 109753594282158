<template>
  <div
    class="bg-[#121212] h-full fixed left-0 w-full lg:w-[--nav-left-width] z-30 lg:z-20 transition-transform overflow-y-scroll scrollbar-hide nav-left p-2"
    :class="[
      { '-translate-x-full lg:-translate-x-full' : ['hidden', 'mini'].includes(navState) },
      { 'pb-[80px] lg:mt-0': ['full'].includes(navState) },
      { 'top-[80px] lg:top-[90px] ' : hasRotator && isUserLogged },
      { 'top-[80px] lg:top-[70px] ' : !hasRotator && isUserLogged },
      { 'top-[57px] lg:top-[70px] ' : hasRotator && !isUserLogged },
      { 'top-[57px] lg:top-[70px] ' : !hasRotator && !isUserLogged },
    ]"
  >
    <div class="flex relative justify-between flex-wrap flex-col items-center min-h-full">
      <div class="w-full">
        <button
          v-if="isUserLogged"
          class="flex w-full items-center text-left metaCoin p-2 mb-1.5 rounded-md"
          type="button"
          @click="openInfoCmsModal({ modalId: 'mwinpts', })"
        >
          <img
            class="h-[40px] w-[40px]"
            src="~/assets/img/logos/MetaWin-logo-white.png"
            loading="lazy"
            height="40"
            width="40"
            decoding="async"
          >
          <div class="pl-2 text-xs font-bold">
            <p class="text-[#00D1FF]">$MWIN Coin</p>
            <p class="flex gap-1">
              {{ isUserLogged ? `Points:` : 'Earn points now' }}
              <span v-if="!mwinPointSummaryDisplay">{{ mwinPointsTotal?.toLocaleString() }}</span>
              <tippy
                v-else
                placement="bottom"
                class="m-0 my-auto p-0 flex"
                @click.stop=""
              >
                {{ mwinPointsTotal?.toLocaleString() }}
                <span class="text-sm ml-1 -mt-[1px]">
                  <span class="icon-ico-info"/>
                </span>
                <template #content>
                  <p class="text-[#00D1FF] pb-2 text-xs font-bold">Points Summary</p>
                  <p class="text-xs" v-if="mwinPointsAvailable">Points: <span class="font-bold">{{ mwinPoints?.toLocaleString() }}</span></p>
                  <p class="text-xs" v-if="mwinPointsOGAvailable">OG Points: <span class="font-bold">{{ mwinPointsOG?.toLocaleString() }}</span></p>
                  <p class="text-xs" v-if="mwinPointsRafAvailable">Referral Points: <span class="font-bold">{{ mwinPointsRaf?.toLocaleString() }}</span></p>
                </template>
              </tippy>
          </p>
          </div>
        </button>
        <!-- <NavigationSectionRewards v-if="isUserLogged"/> -->
        <transition name="bottom-pop">
          <NavigationSectionVipTransfer v-if="isUserLogged && isVipTransferPending"/>
        </transition>
        <div class="mb-5"/>
        <NavigationSectionCasino v-if="isUserLogged"/>
        <NavigationSectionPrizes v-if="isUserLogged"/>
        <!-- <NavigationSectionSports v-if="isUserLogged"/> -->
        <template v-if="isUserLogged && (nuxtApp.$config.public.MARKETS_ENABLED || isTradingEnabled)">
          <p class="w-full font-semibold text-left text-sm pt-2 mb-1.5 text-[#CBCBCB]">New</p>
          <NavigationSectionMarkets v-if="isUserLogged && nuxtApp.$config.public.MARKETS_ENABLED"/>
          <NavigationSectionCryptoFutures v-if="isTradingEnabled"/>
        </template>
        <p v-if="isUserLogged" class="w-full font-semibold text-left text-sm pt-2 mb-1.5 text-[#CBCBCB]">More</p>
        <NavigationSectionSalonRouge v-if="nuxtApp.$config.public?.SHOW_VIP && isUserLogged"/>
        <NavigationSectionMetaWinners v-if="nuxtApp.$config.public?.NFT_HOLDER_ENABLED && isUserLogged"/>
        <NavigationSectionPromotions v-if="isUserLogged" :items="promotionsItems"/>
        <NavigationSectionUser v-if="isUserLogged"/>
        <NavigationSectionCommunity/>
        <NavigationSectionBridge v-if="isUserLogged"/>
        <NavigationSectionSupport v-if="isUserLogged"/>
      </div>
      <PaymentsBanner v-if="isUserLogged" small/>
      <SwitchCompetitionValue
        id="expandedCurrencyToggle"
        class="my-2"
        small
      />
      <UserFlagSwitch small/>
    </div>
  </div>
</template>

<script setup>
import { useIntervalFn } from '@vueuse/core';
import { ALL_NAV_COLLECTIONS } from '@/sanity/gql';

defineProps({
  hasRotator: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  navState,
} = storeToRefs(uiStore);
const { openInfoCmsModal, } = uiStore;

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const rewardStore = useRewardStore();
const { isVipTransferPending, } = storeToRefs(rewardStore);

const tradingStore = useTradingStore();
const { isTradingEnabled, } = storeToRefs(tradingStore);

const nuxtApp = useNuxtApp();

const { $api, $rollbar, $useVillusQuery, } = useNuxtApp();

const mwinPointsTotal = ref(0);
const mwinPointsOG = ref(0);
const mwinPointsOGAvailable = ref(false);
const mwinPoints = ref(0);
const mwinPointsAvailable = ref(false);
const mwinPointsRaf = ref(0);
const mwinPointsRafAvailable = ref(false);

const promotionsItems = ref([]);

const { pause, resume, } = useIntervalFn(fetchOgPoints, 10000, false);

// filteredRoute: used for the rotator on different page layouts
// const filteredRoute = computed(() => {
//   const currentRoute = route.path;
//   const excludedRoutes = ['/contact-us/', '/bridge/', '/promotions/', '/promotions/salon-rouge/',];

//   return excludedRoutes.includes(currentRoute);
// });

const mwinPointSummaryDisplay = computed(() => {
  return mwinPointsOGAvailable.value || mwinPointsAvailable.value || mwinPointsRafAvailable.value;
});

async function fetchOgPoints() {
  if (!isUserLogged.value) { return; }
  try {
    const response = await $api('statistics/ogpoints', {
      method: 'GET',
    });

    if (response?.detail) {
      const detail = response.detail;

      if (detail.og) {
        mwinPointsOG.value = detail.og.totalPoints;
        mwinPointsOGAvailable.value = true;
      }

      if (detail.new) {
        mwinPoints.value = detail.new.totalPoints;
        mwinPointsAvailable.value = true;
      }

      if (detail.raf) {
        mwinPointsRaf.value = detail.raf.totalPoints;
        mwinPointsRafAvailable.value = true;
      }

      mwinPointsTotal.value = mwinPoints.value + mwinPointsOG.value + mwinPointsRaf.value;
    }
  } catch (err) {
    $rollbar.error('fetchOgPoints method failed', err);
  } finally {
    // loading.value = false;
  }
}

function fetchCmsMenuData() {
  const getData = $useVillusQuery(ALL_NAV_COLLECTIONS, { collectionId: 'promotions', });

  getData().then(({ data, error, }) => {
    if (error) {
      $rollbar.error('NavCollections fetchCmsData::', error);
      return;
    }
    if (data?.allNavCollections?.length === 0) {
      promotionsItems.value = [];
    } else {
      const { show, navListItems, } = data.allNavCollections[0];
      promotionsItems.value = show ? navListItems.filter(item => item.show) : [];
    }
  });
}

watch(isUserLogged, (newVal) => {
  if (newVal) {
    fetchOgPoints(); // Initial fetch
    fetchCmsMenuData();
    resume(); // Start or resume the interval
  } else {
    mwinPointsTotal.value = 0;
    mwinPointsOG.value = 0;
    mwinPoints.value = 0;
    mwinPointsRaf.value = 0;
    pause(); // Pause the interval when logged out
  }
}, { immediate: true, });

await fetchOgPoints();
</script>

<style lang="scss" scoped>

.metaCoin {
  background: rgb(20,117,224);
  background: linear-gradient(90deg, rgba(20,117,224,0.25) 25%, rgba(255,255,255,0) 100%);
}
</style>
